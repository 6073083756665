import React, { useEffect, useMemo, useState } from 'react';

import { TextField, type SelectProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem/MenuItem';

import Select from '@mui/material/Select';
import { useDebounce } from '../../shared/hooks/useDebounce';
import useLoggedInCustomer from '../../shared/hooks/useLoggedInCustomer';

const getOptions = (num: number) => Array.from({ length: num }, (_, i) => i + 1);

type Props = Exclude<SelectProps<number>, 'onChange'> & {
  onChange: (value: number) => void,
  includeZero?: boolean
  textBox: boolean
}

export function NumericInput({
  onChange,
  includeZero,
  id,
  textBox,
  ...other
}: Props) {
  const randId = React.useId();
  const controlId = id || randId

  const [thisValue, setThisValue] = useState<number | null>(other.value || null)

  const textInputChangeDebounce = useDebounce((value: number) => {
    onChange(value)
  }, 1000)

  const loggedInCustomer = useLoggedInCustomer();
  const options = useMemo(() => {
    return getOptions(loggedInCustomer.wholesale ? 200 : 10)
  }, [loggedInCustomer.isLoggedIn])

  useEffect(() => {
    if (other.value !== thisValue && other.value) {
      setThisValue(other.value)
    }
  }, [other.value])

  return !textBox ? (
    <Select
      {...other}
      id={controlId}
      aria-controls={controlId}
      onChange={(e) => onChange(e.target.value as number)}
      SelectDisplayProps={{ 'aria-controls': controlId }}
      MenuProps={{ 'aria-controls': controlId }}
      slotProps={{
        input: { 'aria-controls': controlId },
        root: { 'aria-controls': controlId }
      }}
    >
      {includeZero && <MenuItem id={`${controlId}-${0}`} value={0}>Remove</MenuItem>}

      {options.map((option, i) => (
        <MenuItem key={option} id={`${controlId}-${option}`} value={option}>{option}</MenuItem>
      ))}

      {other.value && options.indexOf(other.value) === -1 ? <MenuItem value={other.value} id={`${controlId}-${other.value}`}>{other.value}</MenuItem> : null}
    </Select>
  ) : (
    <TextField
      {...other as any}
      value={thisValue}
      onChangeCapture={undefined}
      ref={undefined}
      id={controlId}
      slotProps={{ htmlInput: { min: 0, step: 0 } }}
      variant='outlined'
      sx={{ ...other.sx, maxWidth: '80px' }}
      onChange={(e) => {
        setThisValue(parseInt(e.target.value));
        textInputChangeDebounce(parseInt(e.target.value))
      }}
    />
  )
}
