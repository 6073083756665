import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

interface CustomerState {
  isLoggedIn: boolean
  wholesale: boolean
  customerTags: string
  accessToken: string
  email: string
}

export const useLoggedInCustomer = (): CustomerState => {
  const [customerState, setCustomerState] = useState<CustomerState>({
    isLoggedIn: false,
    wholesale: false,
    customerTags: '',
    accessToken: '',
    email: '',
  })

  const customerAccessToken = Cookies.get('shopify_access_token')

  useEffect(() => {
    // if (typeof window === 'undefined') return

    const wholesale = Cookies.get('shopify_customer_wholesale') === '1'
    const customerTags = Cookies.get('shopify_customer_tags') || ''
    const accessToken = Cookies.get('shopify_access_token') || ''
    const email = Cookies.get('shopify_customer_email') || ''

    setCustomerState({
      isLoggedIn: !!accessToken,
      wholesale,
      customerTags,
      accessToken,
      email
    })
  }, [customerAccessToken])

  return customerState
}

export default useLoggedInCustomer