import { getRedirectUrl, shopifyAuthOptions } from "../models";

/**
 * Generates a random string for use as a code verifier
 */
const generateCodeVerifier = async (): Promise<string> => {
  const array = new Uint8Array(32);
  crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
};

/**
 * Generates a code challenge from a code verifier using SHA-256
 */
const generateCodeChallenge = async (verifier: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(verifier);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return btoa(String.fromCharCode(...new Uint8Array(hash)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

/**
 * Generates a random nonce value
 */
const generateNonce = (): string => {
  return Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
};

/**
 * Initiates the customer login process by redirecting to Shopify's authentication page
 * Implements PKCE flow for enhanced security
 */
export const initiateCustomerLogin = async (): Promise<void> => {
  const redirectUri = getRedirectUrl(`/auth/callback`);
  const { shopId, clientId } = shopifyAuthOptions;

  // Generate and store PKCE verifier
  const verifier = await generateCodeVerifier();
  const challenge = await generateCodeChallenge(verifier);

  if (typeof window !== 'undefined') {
    localStorage.setItem('code-verifier', verifier);
  }

  // Generate state and nonce
  const state = generateNonce();
  const nonce = generateNonce();

  // Construct authorization URL
  const authorizationRequestUrl = new URL(
    `https://shopify.com/authentication/${shopId}/oauth/authorize`
  );

  // Add required parameters
  authorizationRequestUrl.searchParams.append(
    'scope',
    'openid email customer-account-api:full'
  );
  authorizationRequestUrl.searchParams.append('client_id', clientId);
  authorizationRequestUrl.searchParams.append('response_type', 'code');
  authorizationRequestUrl.searchParams.append('redirect_uri', redirectUri);
  authorizationRequestUrl.searchParams.append('state', state);
  authorizationRequestUrl.searchParams.append('nonce', nonce);
  authorizationRequestUrl.searchParams.append('code_challenge', challenge);
  authorizationRequestUrl.searchParams.append('code_challenge_method', 'S256');

  // Store state and nonce for validation
  if (typeof window !== 'undefined') {
    localStorage.setItem('auth-state', state);
    localStorage.setItem('auth-nonce', nonce);
  }

  // Redirect to Shopify's authentication page
  if (typeof window !== 'undefined') {
    window.location.href = authorizationRequestUrl.toString();
  }
};