import { useMemo } from "react";
import { initiateCustomerLogin } from "../shopify/auth/customer/initiate";
import { initiateLogout } from "../shopify/auth/customer/logout";
import useLoggedInCustomer from "./useLoggedInCustomer";

export const useAccountNavItems = () => {
    const loggedInCustomer = useLoggedInCustomer();
    const navItem = useMemo(() => {
        const myAccountNav = {
            name: 'Account',
            __typename: "ContentfulNavItemGroup",
            id: 'my-account',
            contentful_id: 'my-account',
            navItems: []
        };

        if (loggedInCustomer.isLoggedIn) {
            myAccountNav.navItems.push({
                text: 'Orders',
                __typename: "ContentfulNavItem",
                id: 'my-account-orders',
                href: process.env.GATSBY_SHOPIFY_CUSTOMER_ACCOUNT_URL + '/orders'
            });
            myAccountNav.navItems.push({
                text: 'Subscriptions',
                __typename: "ContentfulNavItem",
                id: 'my-account-subscriptions',
                href: process.env.GATSBY_SHOPIFY_CUSTOMER_ACCOUNT_URL + '/pages/6971b1a1-27f6-4c27-b8b0-3009fd3b921d'
            });
            myAccountNav.navItems.push({
                text: 'Logout',
                __typename: "ContentfulNavItem",
                id: 'my-account-logout',
                onClick: () => { initiateLogout() },
            });
        }
        else {
            myAccountNav.navItems.push({
                text: 'Login',
                __typename: "ContentfulNavItem",
                id: 'my-account-login',
                onClick: () => { initiateCustomerLogin() },
            });
            myAccountNav.navItems.push({
                text: 'Wholesale',
                __typename: "ContentfulNavItem",
                id: 'my-account-wholesale-signup',
                href: '/wholesale'
            });
        }

        return myAccountNav;
    }, [loggedInCustomer.isLoggedIn, loggedInCustomer.wholesale])

    return navItem;
}