exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-callback-tsx": () => import("./../../../src/pages/auth/callback.tsx" /* webpackChunkName: "component---src-pages-auth-callback-tsx" */),
  "component---src-pages-auth-logout-tsx": () => import("./../../../src/pages/auth/logout.tsx" /* webpackChunkName: "component---src-pages-auth-logout-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dosage-tsx": () => import("./../../../src/pages/dosage.tsx" /* webpackChunkName: "component---src-pages-dosage-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-shopify-product-handle-tsx": () => import("./../../../src/pages/products/{ShopifyProduct.handle}.tsx" /* webpackChunkName: "component---src-pages-products-shopify-product-handle-tsx" */),
  "component---src-pages-products-shopify-product-product-type-index-tsx": () => import("./../../../src/pages/products/{ShopifyProduct.productType}/index.tsx" /* webpackChunkName: "component---src-pages-products-shopify-product-product-type-index-tsx" */),
  "component---src-templates-page-contentful-page-index-tsx": () => import("./../../../src/templates/page/contentfulPage/index.tsx" /* webpackChunkName: "component---src-templates-page-contentful-page-index-tsx" */),
  "component---src-templates-page-qr-tsx": () => import("./../../../src/templates/page/qr.tsx" /* webpackChunkName: "component---src-templates-page-qr-tsx" */),
  "component---src-templates-page-shopify-policy-page-tsx": () => import("./../../../src/templates/page/shopifyPolicyPage.tsx" /* webpackChunkName: "component---src-templates-page-shopify-policy-page-tsx" */)
}

