import Cookies from "js-cookie";
import { getRedirectUrl } from "../models";

export const initiateLogout = () => {
    if (typeof window === 'undefined') {
        return;
    }
    const idToken = encodeURIComponent(Cookies.get('shopify_id_token'));

    if (!idToken) {
        return;
    }

    const redirectUri = encodeURIComponent(getRedirectUrl(`/auth/logout`));
    const logoutUrl = `https://shopify.com/authentication/${process.env.GATSBY_SHOPIFY_SHOP_ID}/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`;

    window.location.href = logoutUrl;
}