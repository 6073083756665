
import { PersonOutlineOutlined } from "@mui/icons-material";
import { Divider, IconButton, ListSubheader, Menu, MenuItem, useTheme } from "@mui/material";
import React from "react";
import { useAccountNavItems } from "../../shared/hooks/useAccountNavItems";
import useLoggedInCustomer from "../../shared/hooks/useLoggedInCustomer";
import { initiateCustomerLogin } from "../../shared/shopify/auth/customer/initiate";

export const AccountNavButton = () => {
    const theme = useTheme();
    const accountNav = useAccountNavItems();
    const customerLogin = useLoggedInCustomer();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return !customerLogin.isLoggedIn ? (
        <IconButton style={{ color: theme.palette.common.white }} id="account-nav-button-login" size="large" aria-label={`Login to your Blootropic account`} onClick={() => initiateCustomerLogin()}>
            <PersonOutlineOutlined fontSize="inherit" />
        </IconButton>
    ) : (
        <>
            <IconButton
                style={{ color: theme.palette.common.white }}
                aria-label="More account options"
                id="account-nav-button-dropdown"
                size="large"
                aria-controls={open ? 'account-nav-button-dropdown-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <PersonOutlineOutlined />
            </IconButton>
            <Menu
                id="account-nav-button-dropdown-menu"
                MenuListProps={{
                    'aria-labelledby': 'account-nav-button-dropdown',
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                anchorEl={anchorEl}
                disablePortal
                open={open}
                onClose={handleClose}
            >
                {customerLogin.isLoggedIn && <><ListSubheader>{customerLogin.email || 'Logged in'}</ListSubheader>
                    <Divider sx={{ my: 0.5 }} /></>}
                {accountNav.navItems.map((option) => (
                    <MenuItem key={option} href={option.href} onClick={() => { handleClose(); if (option.onClick) option.onClick(); }}>
                        {option.text}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}