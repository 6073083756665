import React from 'react';

import NavItemGroupStatic from './nav-item-group-static';

export type NavItemGroupNavItem = {
  id: string
  href: string
  text: string
  description?: string
  contentfulPreviewProps?: any
  onClick?: () => void
}

export interface NavItemGroupProps {
  name: string
  navItems: NavItemGroupNavItem[]
  disableDropDown?: boolean
  contentfulPreviewProps?: any
}

export default function NavItemGroup({
  disableDropDown,
  ...props
}: NavItemGroupProps) {
  return <NavItemGroupStatic {...props} />
  // return disableDropDown ? (
  //   <NavItemGroupStatic {...props} />
  // ) : (
  //   <NavItemGroupWithDropdown {...props} />
  // )
}
