import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import { Add, Check, Delete, ShoppingBagOutlined } from '@mui/icons-material';
import {
  Badge, Box, Button, Chip, IconButton,
  Stack, SxProps, TableCell, TableRow, Theme, Tooltip, Typography, useTheme
} from '@mui/material';
import {
  BuyNowButton, ShopPayButton, useCart, useCartLine
} from '@shopify/hydrogen-react';
import { CurrencyCode, MoneyV2 } from '@shopify/hydrogen-react/storefront-api-types';

import { indexOfFirstProductImage } from '../../pages/products/{ShopifyProduct.handle}';
import { useAnalytics } from '../../shared/analytics';
import { useAutomaticDiscountList } from '../../shared/hooks/useAutomaticDiscountList';
import useLoggedInCustomer from '../../shared/hooks/useLoggedInCustomer';
import { formatCurrency } from '../../shared/utils';
import { NumericInput } from '../controls/numeric-input';


export const useCartStyling = (theme: Theme) => ({
  productImageCell: {
    display: {
      xs: "none",
      sm: "none",
      md: "none",
      lg: 'table-cell',
      xl: 'table-cell',
    },
  },
  lineItemSubtotalCell: {
    display: {
      xs: "none",
      sm: "none",
      md: 'table-cell',
      lg: 'table-cell',
      xl: 'table-cell',
    },
  },
  cartDesktopActionsCell: {
    display: {
      xs: "none",
      sm: "none",
      md: 'table-cell',
      lg: 'table-cell',
      xl: 'table-cell',
    },
  },
  cartMobileActions: {
    display: {
      xs: "block",
      sm: "block",
      md: 'none',
      lg: 'none',
      xl: 'none',
    },
  },
  cartItemTable: {
    "& td": {
      fontSize: theme.typography.body1.fontSize,
      borderBottom: 'none',
      paddingLeft: {
        xs: 0,
        sm: 0,
        md: 'initial',
      },
      paddingRight: {
        xs: 0,
        sm: 0,
        md: 'initial',
      },
      paddingBottom: theme.spacing(2),
    },
    "& th": {
      fontSize: theme.typography.body1.fontSize,
      borderBottom: 'none',
      paddingLeft: {
        xs: 0,
        sm: 0,
        md: 'initial',
      },
      paddingRight: {
        xs: 0,
        sm: 0,
        md: 'initial',
      },
      paddingBottom: theme.spacing(2),
    }
  },
  tableFooter: {
    "& td": {
      paddingBottom: theme.spacing(0.2),
      fontSize: theme.typography.body1.fontSize,
      borderBottom: 'none',
    },
    "& th": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(0.2),
      fontSize: theme.typography.body1.fontSize,
      borderBottom: 'none',
    }
  }
})


type AddToCartControlProps = {
  variantId: string;
  quantity: number;
  available: boolean;
  pageType: string
  product: Queries.ShopifyProductCardFragment
  withBuyNow?: boolean
  withShopPay?: boolean
  pathname: string
}

export function AddToCart({ variantId, quantity, available, pageType, product, withBuyNow, withShopPay, pathname }: AddToCartControlProps) {
  const { status, linesAdd } = useCart();
  const loading = status === 'updating' || status === 'creating'

  const rootId = product.id + ''
  const btnId = rootId + '-btn'
  const buyNowId = rootId + '-buynow'
  const iconId = rootId + '-icon'

  const analytics = useAnalytics();

  const simple = !withBuyNow && !withShopPay;

  const [justAddedToCart, setJustAddedToCart] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);

  useEffect(() => {
    // console.log('heree status', status)
    if (loading && pathname !== '/cart' && pathname !== '/cart/') {
      setJustAddedToCart(true);
      setAddedToCart(true);
      setTimeout(() => {
        setJustAddedToCart(false)
      }, 4000)
    }
  }, [status])

  function addToCart(e) {
    e.preventDefault()
    linesAdd([
      { quantity, merchandiseId: variantId }
    ])

    analytics.trackAddToCart(
      [
        {
          ...product
        }
      ]
      , variantId, quantity);
  }

  return (
    <Stack direction='row' justifyContent={'space-between'} alignItems={'center'} spacing={2}>
      <Button loading={loading}
        id={rootId}
        disabled={!available || loading}
        onClick={addToCart}
        // color={justAddedToCart ? 'success' : 'primary'}
        color={'primary'}
        startIcon={justAddedToCart ? <Check id={iconId} /> : <Add id={iconId} />}
        sx={[simple ? {
          width: '100%'
        } : {
          width: 'auto'
        }]}
        variant={simple ? 'contained' : 'contained'}>{available ? "Add to cart" : "Out of Stock"}</Button>
      {addedToCart ? (
        <Button variant='contained' color='success' component={Link}
          id={btnId} to='/cart'
          sx={[simple ? {
            width: '100%'
          } : {
            width: 'auto'
          }]} startIcon={<Check />}>Checkout</Button>)
        : <></>}
      {available && withBuyNow && <BuyNowButton as={Button} id={buyNowId} loading={loading}
        disabled={loading}
        variantId={variantId}
        variant='contained'>{"Buy now"}</BuyNowButton>}
      {available && withShopPay && <ShopPayButton
        width='150px'
        storeDomain={process.env.GATSBY_SHOPIFY_MYSHOPIFY_DOMAIN}
        variantIds={[variantId]} />
      }
    </Stack>
  );
}

type CartButtonProps = {
  quantity: number;
  onClick?: () => void;
}

export function CartButton({ quantity, onClick }: CartButtonProps) {
  const theme = useTheme();

  return (

    <IconButton style={{ color: theme.palette.common.white }} size="large" component={Link} aria-label={`Shopping cart has ${quantity} items`} onClick={onClick} to="/cart">
      <Badge badgeContent={quantity > 0 ? quantity : undefined} color="error">
        <ShoppingBagOutlined fontSize="inherit" />
      </Badge>
    </IconButton>
  )
}

type CartLineItemProps = {
  isCartLoading: boolean;
}

export function CartLineItem({ isCartLoading }: CartLineItemProps) {
  const theme = useTheme();
  const styles = useCartStyling(theme)
  const item = useCartLine();
  const { linesUpdate, linesRemove, } = useCart();

  const loggedInCustomer = useLoggedInCustomer();

  const handleRemove = () => {
    linesRemove([item.id])
  }

  const handleQuantityChange = (value: number) => {
    if (Number(value) < 0) {
      return
    }

    const numberVal = Number(value);

    if (numberVal >= 1) {

      linesUpdate([
        {
          id: item.id,
          merchandiseId: item.merchandise.id,
          quantity: numberVal
        }
      ])
    }
    else if (numberVal === 0) {
      linesRemove([item.id])
    }
  }

  const allLoading = false;

  const productRoute = `/products/${item.merchandise.product.handle}`

  // if (isCartLoading) return <Skeleton />

  const discounts = useAutomaticDiscountList();
  // const discountedPriceSingle = discounts.discountPriceForPrice(1, item.cost.amountPerQuantity as MoneyV2);

  // console.log('heree item', item)

  let discountedUnitPrice: MoneyV2;
  let discountedTotal: MoneyV2;
  let nonDiscountTotal: MoneyV2 = {
    currencyCode: item.cost.subtotalAmount.currencyCode as CurrencyCode,
    amount: item.cost.subtotalAmount.amount as string,
  };
  if (item.discountAllocations && item.discountAllocations.length > 0) {
    const discountTotal = (item.discountAllocations.map(e => parseFloat(e.discountedAmount?.amount || '0')).reduce((a, b) => a + b, 0))

    discountedTotal = {
      amount: (parseFloat(item.cost.subtotalAmount.amount) - discountTotal).toString(),
      currencyCode: item.cost.amountPerQuantity.currencyCode
    };

    discountedUnitPrice = {
      amount: ((parseFloat(item.cost.subtotalAmount.amount) - discountTotal) / item.quantity).toString(),
      currencyCode: item.cost.amountPerQuantity.currencyCode
    }
  }
  else {
    discountedUnitPrice = discounts.discountPriceForPrice(item.quantity, item.cost.amountPerQuantity as MoneyV2);
    // discountedUnitPrice = discounts.discountPriceForPrice(item.quantity, item.cost.amountPerQuantity as MoneyV2);
    discountedTotal = {
      amount: (parseFloat(discountedUnitPrice.amount) * item.quantity).toString(),
      currencyCode: discountedUnitPrice.currencyCode
    };
    // discountedTotal = { amount: item.cost.subtotalAmount.amount, currencyCode: item.cost.subtotalAmount.currencyCode };
    // discountedUnitPrice = { amount: item.cost.amountPerQuantity.amount, currencyCode: item.cost.amountPerQuantity.currencyCode }
  }

  // console.log('heree item cost', { discountAllocations: item.discountAllocations, discounts, nonDiscountTotal: nonDiscountTotal.amount, discountedTotal: discountedTotal.amount, discountedUnitPrice: discountedUnitPrice.amount })

  // console.log('heree image', item.merchandise.image)

  // console.log('heree image', item.merchandise.product.media)

  return (
    <TableRow
      key={item.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell sx={styles.productImageCell}>
        {/* <ShopifyImage data={item.merchandise.image} height="100px" /> */}
        {/* <img src={item.merchandise.image.url} style={{ height: "150px" }} /> */}
        <img src={item.merchandise?.product?.media?.nodes[indexOfFirstProductImage].previewImage?.url || item.merchandise.image.url} style={{ height: "150px" }} />
      </TableCell>
      <TableCell component="th" scope="row">
        <Stack gap={1}>
          <Stack gap={0.2}>
            {/* <Typography variant='body1' component='div'><Button variant='text' sx={{ p: 0 }} component={Link} to={productRoute}>{antiSlugify(item.merchandise.product.productType)}</Button></Typography> */}
            <Typography variant='body1' component='div'><Button variant='text' sx={{ p: 0 }} component={Link} to={productRoute}>{item.merchandise.product.title}</Button></Typography>
          </Stack>
          <Stack gap={0.2}>
            {item.merchandise.selectedOptions?.map(i =>
              <Typography key={i.name} variant='subtitle2' component='div'>{i.name} - {i.value}</Typography>
            )}
          </Stack>
        </Stack>
      </TableCell>
      <TableCell align="right" sx={styles.lineItemSubtotalCell}><MoneyDisplay money={discountedUnitPrice} quantity={1} nonDiscounted={item.cost.amountPerQuantity as MoneyV2} /></TableCell>
      <TableCell align="right">
        <NumericInput
          id={`quantity-${item.id}`}
          disabled={allLoading}
          textBox={loggedInCustomer.wholesale}
          value={item.quantity}
          includeZero={true}
          aria-label="Quantity"
          variant="outlined"
          size="small"
          onChange={(v) => handleQuantityChange(v)}
        />
      </TableCell>
      <TableCell align="right"><MoneyDisplay compactOnMobile money={discountedTotal} nonDiscounted={nonDiscountTotal} /></TableCell>
      {/* <TableCell align="right"><MoneyDisplay money={subtotal} /></TableCell> */}
      <TableCell align="right" sx={styles.cartDesktopActionsCell}>
        <Tooltip title="Remove">
          <IconButton onClick={handleRemove} disabled={allLoading} aria-label={`Remove ${item.merchandise.product.title} from cart`}>
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

type MoneyDisplayProps = {
  money: Queries.ShopifyMoneyV2 | MoneyV2
  nonDiscounted?: Queries.ShopifyMoneyV2 | MoneyV2
  direction?: 'row' | 'column' | 'column-reverse' | 'row-reverse'
  showSavePill?: boolean
  savePillAsValue?: boolean
  compactOnMobile?: boolean
  invertSaveDirection?: boolean
  totalSxProps?: SxProps
  chipPrefix?: string
  valueSuffix?: string
  quantity?: number
  priceDirection?: 'row' | 'column' | 'column-reverse' | 'row-reverse'
}

export const MoneyDisplay = ({ priceDirection, money, nonDiscounted, quantity, direction = 'column', showSavePill, savePillAsValue, invertSaveDirection, compactOnMobile, totalSxProps, valueSuffix, chipPrefix = 'Sale - Save ' }: MoneyDisplayProps) => {
  const parsed = money as MoneyV2;
  const theme = useTheme()
  const qty = quantity || 1;
  const parsedNonDiscounted = nonDiscounted ? { currencyCode: nonDiscounted.currencyCode, amount: nonDiscounted.amount } as MoneyV2 : null;

  const discountValue = parsedNonDiscounted ? (parseFloat(parsedNonDiscounted.amount || '0')) - (parseFloat(parsed?.amount || '0')) : 0;
  const discountPercent = nonDiscounted ? (discountValue / (parseFloat(parsedNonDiscounted.amount))) * 100 : 0;

  const hideOnMobile = { display: compactOnMobile ? { xs: 'none', sm: 'none', md: 'initial' } : undefined }

  const totalParsed = {
    ...parsed,
    amount: (parseFloat(parsed?.amount || '0')).toString()
  }
  const discountValueParsed = {
    ...parsed,
    amount: discountValue.toString()
  }

  return (
    <Stack direction={priceDirection ? priceDirection : direction} gap={direction === 'column' ? 1 : 3}>
      <Stack gap={direction === 'column' ? 1 : 3} direction={direction === 'column' && invertSaveDirection ? 'column-reverse' : (direction === 'row' && invertSaveDirection ? 'row-reverse' : direction)}>
        <Box>
          <Typography component="div" sx={{ ...totalSxProps }}>{formatCurrency(totalParsed, undefined, qty)}{valueSuffix}</Typography>
        </Box>

        {parsedNonDiscounted && discountValue ? (
          <Box sx={hideOnMobile}>
            <Typography component="div" sx={{ textDecoration: 'line-through', color: theme.palette.text.secondary }}>{formatCurrency(parsedNonDiscounted, undefined, qty)}</Typography>
          </Box>
        ) : <></>
        }
      </Stack>

      {parsedNonDiscounted && showSavePill && discountPercent && !savePillAsValue && discountValue ? <Chip size='small' color='success' label={`${chipPrefix}${discountPercent.toFixed(0)}%`} /> : <></>}
      {parsedNonDiscounted && showSavePill && discountPercent && savePillAsValue && discountValue ? <Chip size='small' color='success' label={`Save ${formatCurrency(discountValueParsed, true, qty)}`} /> : <></>}
    </Stack>
  )
}