
export const cartFragment = (
  /* GraphQL */
  (`
fragment CartFragment on Cart {
  id
  checkoutUrl
  totalQuantity
  buyerIdentity {
    countryCode
    customer {
      id
      email
      firstName
      lastName
      displayName
    }
    email
    phone
  }
  discountAllocations {
    ...on CartAutomaticDiscountAllocation {
     title
    }    
    ...on CartCodeDiscountAllocation {
     code
    } 
    ...on CartCustomDiscountAllocation {
     title
    }
    discountedAmount {
      amount
    }
  } 
  lines(first: $numCartLines) {
    edges {
      node {
        id
        quantity
        attributes {
          key
          value
        }
        cost {
          totalAmount {
            ...MoneyFragment
          }
          compareAtAmountPerQuantity {
            ...MoneyFragment
          }
          amountPerQuantity {
            ...MoneyFragment
          }
          subtotalAmount {
            ...MoneyFragment
          }
        }
        discountAllocations {
          discountedAmount {
            amount
            currencyCode
          }
        }
        merchandise {
          ... on ProductVariant {
            id
            availableForSale
            compareAtPrice {
              ...MoneyFragment
            }
            price {
              ...MoneyFragment
            }
            requiresShipping
            title
            image {
              ...ImageFragment
            }
            product {
              handle
              title
              id
              productType
              media(first: 2) {
                nodes {
                  previewImage {
                    url
                  }
                }
              }
            }
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
  }
  cost {
    subtotalAmount {
      ...MoneyFragment
    }
    totalAmount {
      ...MoneyFragment
    }
    totalDutyAmount {
      ...MoneyFragment
    }
    totalTaxAmount {
      ...MoneyFragment
    }
  }
  note
  attributes {
    key
    value
  }
  discountCodes {
    code
    applicable
  }
}

fragment MoneyFragment on MoneyV2 {
  currencyCode
  amount
}
fragment ImageFragment on Image {
  id
  url
  altText
  width
  height
}
`)
);