

export interface InitiateLoginOptions {
    shopId: string;
    clientId: string;
}

export const shopifyAuthOptions: InitiateLoginOptions = {
    shopId: process.env.GATSBY_SHOPIFY_SHOP_ID!,
    clientId: process.env.GATSBY_SHOPIFY_CUSTOMERACCOUNTSAPI_CLIENTID!,
}

export const getRedirectUrl = (path: string) => {
    return `https://${window.location.hostname}${window.location.port !== '443' && window.location.port !== '' ? (':' + window.location.port) : ''}${path}`;
}

export const getWholesaleDiscountPercent = (quantity: number) => {
    if (quantity <= 5) return 30;
    if (quantity <= 10) return 35;
    if (quantity <= 30) return 40;
    if (quantity <= 50) return 45;
    if (quantity <= 100) return 50;
    return 55;
}