import { Options } from '@contentful/rich-text-react-renderer';
import { UseMoneyValue } from '@shopify/hydrogen-react/dist/types/useMoney';
import { MoneyV2 } from '@shopify/hydrogen-react/storefront-api-types';
import { ContentfulRichTextGatsbyReference, renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { startCase } from 'lodash-es';
import { ReactNode } from 'react';

export const formatCurrency = (amount: MoneyV2 | undefined | UseMoneyValue, allowZeroTrim?: boolean, quantity?: number) => {
  if (amount === undefined) return '';

  const qty = quantity || 1;

  const currencyCode = ((amount as MoneyV2)?.currencyCode || (amount as UseMoneyValue)?.currencyCode);

  if (!currencyCode) {
    console.warn('Currency code not found in amount', amount);
    return '';
  };

  let theAmount: number = typeof amount === 'number' ? amount : parseFloat(amount.amount);
  theAmount = theAmount * qty;

  let val = theAmount.toLocaleString(undefined, { style: 'currency', currency: currencyCode, maximumFractionDigits: 2 })

  if (val && val.startsWith('A')) {
    val = val.substring(1)
  }
  if (val && allowZeroTrim && val.endsWith('.00')) {
    return val.slice(0, -3)
  }
  return val;
}

export function sortAlphabetically(a: any, b: any, propName: string): number {
  var textA = a[propName].toUpperCase();
  var textB = b[propName].toUpperCase();
  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}

export function isBot() {
  if (typeof window === 'undefined') return false;
  return (navigator.userAgent.includes('Chrome-Lighthouse') || navigator.userAgent.includes('Googlebot'))
}

export function isTrackingEnabled() {
  if (typeof window === 'undefined') return false;
  if (navigator.userAgent.includes('Chrome-Lighthouse')) return false;
  return !isBot();
}

export const getCurrencySymbol = (currency, locale = undefined) => {
  if (!currency) {
    return
  }

  const formatter = Intl.NumberFormat(locale, {
    currency,
    style: "currency",
  })
  const parts = formatter.formatToParts(100)
  const { value: symbol } = parts.find((part) => part.type === "currency")
  const formatted = formatter.format(100)
  const symbolAtEnd = formatted.endsWith(symbol)
  return { symbol, symbolAtEnd }
}

export const antiSlugify = (slug: string) => {
  return startCase(slug.replace(/-/g, ' '));
}

type ContentfulLivePreviewData = RenderRichTextData<null> & {
  json: any
}

const getContentfulLivePreviewDataOrFallback = (data: any) => {
  const typed = data as ContentfulLivePreviewData;
  return { raw: typed.json ? JSON.stringify(typed.json) : typed.raw }
}


export function renderContentfulRichText<
  TReference extends ContentfulRichTextGatsbyReference
>(data: RenderRichTextData<TReference>, options?: Options): ReactNode {
  // return <></>
  return renderRichText(getContentfulLivePreviewDataOrFallback(data), options);
}
