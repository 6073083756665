import { Link as GatsbyLink } from 'gatsby';
import { GatsbyImage, GatsbyImageProps, getSrcSet, IGatsbyImageData } from 'gatsby-plugin-image';
import isAbsoluteURL from 'is-absolute-url';
import React from 'react';

import { ButtonOwnProps, Chip, Button as MuiButton, Stack, useTheme } from '@mui/material';
import {
  useContentfulInspectorMode, useContentfulLiveUpdates
} from '../shared/hooks/contentful';
import { initiateCustomerLogin } from '../shared/shopify/auth/customer/initiate';

export interface HomepageLink {
  id: string
  href: string
  url: string
  text: string
  contentful_id: string
}

interface ContentfulAssetImageProps
  extends Omit<Omit<GatsbyImageProps, "alt">, "image"> {
  asset: ContentfulAsset
  alt?: string
  aboveFold?: boolean
  skipGatsbyImage?: boolean
  width?: string,
  height?: string,
  contentfulPreviewProps?: any
}

export type ContentfulAsset = {
  id: string
  title?: string
  url?: string
  contentful_id: string
  gatsbyImageData?: IGatsbyImageData
  localFile?: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

/// Supports SVGs, childImageSharp is null for SVGs
export function ContentfulAssetImage({
  asset,
  loading,
  alt,
  style,
  imgStyle,
  aboveFold,
  width,
  height,
  contentfulPreviewProps,
  title,
  skipGatsbyImage
}: ContentfulAssetImageProps) {
  const imageData = asset?.localFile?.childImageSharp?.gatsbyImageData || asset?.gatsbyImageData
  // const imageData = asset.localFile ? getImage(asset.localFile) : (asset.gatsbyImageData ? getImage(asset.gatsbyImageData) : undefined)

  // if (skipGatsbyImage) {
  //   console.log('heree', asset, imageData)
  // }

  const dimensions = {}
  if (width) {
    dimensions['width'] = width
  }
  if (height) {
    dimensions['height'] = height
  }

  return (!skipGatsbyImage && asset && imageData) ? (
    <GatsbyImage
      loading={loading || (aboveFold ? "eager" : "lazy")}
      image={imageData}
      title={title || asset.title}
      // width={width}
      // height={height}
      alt={alt || asset.title}
      imgClassName={aboveFold ? "above-fold" : undefined}
      imgStyle={aboveFold ? { ...style, transition: '', willChange: '', ...dimensions } : { ...style, ...dimensions }}
      {...contentfulPreviewProps}
    />
  ) : asset && asset.url ? (
    <img
      style={style || imgStyle}
      width={imgStyle && imgStyle.width ? imgStyle.width : (width ? width : undefined)}
      height={imgStyle && imgStyle.height ? imgStyle.height : (height ? height : undefined)}
      src={(imageData?.images?.sources && imageData?.images?.sources.length > 0 ? imageData?.images?.sources[0].srcSet.split(' ')[0] : null) || imageData?.images?.fallback?.src || asset.url}
      srcSet={getSrcSet(imageData)}
      loading={loading || (aboveFold ? "eager" : "lazy")}
      alt={alt || (asset && asset.title)}
      title={title || asset.title}
      {...contentfulPreviewProps}
    />
  ) : (
    <></>
  )
}

type KickerProps = {
  icon?: ContentfulAsset
  label: string
  background?: string
}

export function Kicker({ icon, background, ...props }: KickerProps) {
  const theme = useTheme();

  return (
    <>
      <Chip
        {...props}
        icon={
          icon ? (
            <ContentfulAssetImage
              asset={icon}
              alt={props.label}
              {...icon}
              imgStyle={{ height: "20px", width: "20px" }}
            />
          ) : undefined
        }
        label={props.label}
        sx={{
          padding: "0.5rem 1rem",
          background: background || theme.palette.primary.light,
          fontWeight: 500,
          color: theme.palette.primary.contrastText,
          fontSize: "1rem",
          lineHeight: "1.5rem",
        }}
        variant="filled"
        color="primary"
      />
    </>
  )
}

interface LinkProps {
  to?: string
  href?: string
}

export function Link(
  { to, href, ...props }: LinkProps,
  ref: React.ForwardedRef<unknown>,
) {
  const url = href || to || ""
  if (isAbsoluteURL(url)) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      (<a href={url} target="_blank" {...props} />)
    );
  }
  return <GatsbyLink to={url} {...props} />
}

type NavLinkProps = LinkProps & ButtonProps & {
  contentfulPreviewProps?: any
  children: React.ReactNode
  id: string
}

type NavButtonLinkProps = LinkProps & ButtonProps & {
  contentfulPreviewProps?: any
  children: React.ReactNode
  id: string
}


export function NavLink({ contentfulPreviewProps, id, ...props }: NavLinkProps) {
  const link = props.href || props.to ? React.forwardRef<unknown, LinkProps>((props, ref) =>
    Link(props, ref),
  ) : undefined

  return <MuiButton variant="text" component={link} {...contentfulPreviewProps} {...props} id={id} />
}

export function NavButtonLink({ contentfulPreviewProps, id, ...props }: NavButtonLinkProps) {
  return <MuiButton variant="text" {...contentfulPreviewProps} {...props} id={id} />
}

type ButtonProps = ButtonOwnProps & {
  href?: string
  to?: string
  contentfulPreviewProps?: any
  onClick?: () => void
}

export function Button({
  color = "primary",
  variant = "contained",
  href,
  to,
  sx,
  fullWidth,
  children,
  contentfulPreviewProps,
  ...other
}: ButtonProps) {
  let url = href || to
  const isLoginButton = url && url === '/auth/login'
  const hasUrl = !isLoginButton && Boolean(url);

  const isAbsolute = hasUrl && isAbsoluteURL(url)

  let onClick = other.onClick;
  if (isLoginButton) {
    onClick = () => initiateCustomerLogin();
    url = null;
  }

  return isAbsolute || !hasUrl ?
    <MuiButton
      variant={variant}
      color={color}
      href={url}
      rel='noopener noreferrer'
      target="_blank"
      fullWidth={fullWidth}
      sx={sx}
      {...contentfulPreviewProps}
      {...other}
      onClick={onClick}
    >
      {children}
    </MuiButton> :
    <GatsbyLink to={url}>
      <MuiButton
        fullWidth={fullWidth}
        variant={variant}
        color={color}
        sx={sx}
        {...contentfulPreviewProps}
        {...other}
        onClick={onClick}
      >
        {children}
      </MuiButton>
    </GatsbyLink>
}

interface ButtonListProps {
  links: HomepageLink[]
  reversed?: boolean
  buttonVariant?: "text" | "outlined" | "contained"
}
interface ButtonListItemProps {
  link: HomepageLink
  buttonVariant?: "text" | "outlined" | "contained"
}

export function ButtonList({
  links = [],
  reversed = false,
  ...props
}: ButtonListProps) {
  if (!links || links.length === 0) return null
  return (
    <Stack>
      {links &&
        links.map((link, i) => (
          <div key={link.id}>
            <ButtonListItem {...props} link={link} />
          </div>
        ))}
    </Stack>
  )
}

export function ButtonListItem({
  link,
  ...props
}: ButtonListItemProps) {
  const inspectorProps = useContentfulInspectorMode();
  const data = useContentfulLiveUpdates({
    ...link,
    sys: { id: link.contentful_id },
  });

  return (
    <Button href={data.href} variant={props.buttonVariant} {...inspectorProps({
      entryId: link.contentful_id,
      fieldId: 'text',
    })}>
      {data.text}
    </Button>
  )
}
