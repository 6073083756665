import React, { useEffect } from 'react';

import Footer from './footer';
import Header from './header';

import { useJudgeme } from '@judgeme/shopify-hydrogen';
import { useCart } from '@shopify/hydrogen-react';

interface LayoutProps {
  children?: React.ReactNode
  location: Location
  isSiteFramed?: boolean
}

const Layout: React.FC<LayoutProps> = ({ children, location, isSiteFramed }) => {
  const reviewsEnabled = Boolean(process.env.GATSBY_JUDGEME_PUBLIC_TOKEN);
  const cart = useCart();

  if (reviewsEnabled) {
    useJudgeme({
      shopDomain: process.env.GATSBY_SHOPIFY_MYSHOPIFY_DOMAIN,
      publicToken: process.env.GATSBY_JUDGEME_PUBLIC_TOKEN,
      cdnHost: 'https://cdn.judge.me',
      delay: 500,
    });
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const discount = urlParams.get('discount');
    if (discount) {
      cart.discountCodesUpdate([discount]);
    }
  }, [location])

  return (
    <>
      <Header location={location} isSiteFramed={isSiteFramed} />
      {children}
      <Footer isSiteFramed={isSiteFramed} />
    </>
  )
}

export default Layout
